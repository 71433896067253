<template>
    <div class="edu-frame">
        <edu-nav-bar title="已报读班级" :hideBack="isredirect" :show="show" @showPopup="show=true"
                     @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="pop-btn" @click="toHistory"><div>查看历史</div><div>报读班级</div></div>
            <div class="edu-group" style="margin: 20px auto 15px;">
                <div class="title-tips">说明：</div>
                <div style="width: 95%;margin: 4px auto 10px;">
                    <div v-if="cust.loginType == LOGINTYPE_CHILD">退款将在3至5个工作日退还到您的账户。</div>
                    <div v-else><span>退款结果可能会有延迟，详情请查询您的微信/支付宝通知。<br/></span>
                    <span style="color:red;font-size: 15px;font-weight: bold;">(2025年2月15日前，全额退费。<br/>2025年2月16日~3月30日，只退学费的50%。<br/>2025年3月31日起，不允许退款。)</span>
                    </div>
                </div>
                <div style="width: 95%;margin: 4px auto 10px;">
                    点击打印课程列表可查看开学时间、上课地点等讯息。
                </div>

            </div>
            <div class="edu-group" style="margin: 20px auto 15px;">
                <div class="title-tips">您已经报读的班级列表：</div>
            </div>
            <div v-for="(master, key) in regClassMasters" :key="key">
                <div class="class-list">
                    <div class="edu-group class-item" style="flex-direction: row;display: flex;">
                        <div class="class-detail">
                            <div class="class-detail-title">单号：{{key}}</div>
                            <div class="class-detail-more">提交时间：{{master[0].billDate | filterDate}}</div>
                        </div>
                      <div>
                        <van-button v-if="master[0].etaxUrl != null" type="info" size="small" @click="toTaxURL(master[0].etaxUrl)">下载发票</van-button>
                        <!--                                  <van-button v-else type="warning" size="small">发票处理中</van-button>-->
                      </div>
                    </div>
                    <div v-for="(detail, detailIndex) in master" :key="detailIndex">
                        <div class="edu-group class-item">
                            <!--<van-checkbox v-if="detail.isNoRefund != 1" style="margin-right: 13px;" v-model="detail.checked"
                                          @change="checkClasses(detail)"></van-checkbox>-->
                            <div class="class-detail">
                                <div class="class-detail-title">
                                    <div class="class-detail-title-name">{{detail.className}}</div>
                                    <van-tag type="success">已缴费</van-tag>
                                </div>
                                <div class="class-detail-more">
                                    <div class="class-detail-title-name">
                                        <div>上课时间：{{detail.classTime}}</div>
                                        <div class="class-detail-row">
                                            <div class="class-detail-row-title">任课老师：{{detail.teacherId}}</div>
                                            <div class="class-detail-row-title">学费：<label class="txt-price">{{detail.rcMoney}}元</label>
                                            </div>
                                        </div>
                                        <div class="class-detail-row">
                                            <div class="class-detail-row-title">上课地点：{{detail.classRoomName}}</div>
                                        </div>
                                        <div class="class-detail-row">
                                            <div class="class-detail-row-title">上课时间：{{detail.termBegins}}</div>
                                            <div style="display: flex;justify-content: flex-end;align-items: center;">
                                              <!-- 秋季班不允许退费 -->
                                              <!-- 老人 暑期班 要隐藏, 非老人暑期班则显示 -->
<!--                                              && detail.classType2 != 4-->
                                              <van-button v-if="detail.isFreeze == 0  && detail.isNoRefund != 1 && detail.depId != '000100000000011G'" :loading="loading" square type="danger" size="small" @click="refundClassById(detail)">
                                                申请退款
                                              </van-button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <hr class="class-split"/>
            </div>

        </div>
        <div class="bottom-frame">
            <van-button :loading="loading" square block type="default" color="#F57625" @click="printClasses">
                <div>打印课程列表</div>
            </van-button>
<!--            <van-button :loading="loading" square block type="danger" @click="refund">
                申请退款
            </van-button>-->
        </div>

    </div>
</template>
<script>
    /*Checkbox*/
    import {Button, Tag} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "@/api/Tools";
    import EduConstants from '@/api/EduConstants';
    import XEUtils from 'xe-utils'

    export default {
        components: {
            EduNavBar,
            VanButton: Button,
            VanTag: Tag,
            /*VanCheckbox: Checkbox*/
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
                cust: Tools.getCurCust(),
                LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
                LOGINTYPE_OLD: EduConstants.LOGINTYPE_OLD,
                show: false,
                isredirect: false, // 是否重定向过来的
                /*regClassMasters: [
                    {billNo: '002159848022481001', regClasses: [{regClassId: '1', checked: false}, {regClassId: '2',checked: false}]},
                    {billNo: '002159848022481002', regClasses: [{regClassId: '3', checked: false}]},
                ],*/
                regClassMasters: [],
                datas: [],
                payTotal: 0,
                reportData: '',
                refunds: [],
                loading: false
            }
        },
        methods: {
            toHistory() {
                this.$router.push({ name: 'lsbdbj' })
            },
            printClasses() {
                // 打印课程列表
                /*this.$dialog({title: '提示', message: '点击可查看开学时间、上课地点等信息。'}).then(() => {

                })*/
                this.$router.push({ name: 'dykclb' })
            },
            refund() {
                // 申请退款
                if (this.refunds.length <= 0) {
                    this.$dialog({title: '提示', message: '请选择需要退款的课程。'})
                    return
                }
                /*if (this.cust.loginType != this.LOGINTYPE_CHILD) {
                    if (XEUtils.toDateString(new Date(), 'yyyyMMdd') >= '20211006') {
                        this.$dialog({title: '提示', message: '2021年10月6日起，不允许退款。'})
                        return;
                    }
                }*/

                // 如果是老人班，则
                // 本阶段申请退班，退回实缴学费的50%。具体规定见招生简章。
                if (this.cust.loginType != this.LOGINTYPE_CHILD) {
                  this.$dialog.confirm({title: '是否继续退费？', message: '退费具体规定见招生简章。'}).then(() => {
                      this.submitRefund()
                  })
                  // this.submitRefund()
                } else {
                    this.submitRefund()
                }


            },
            submitRefund() {
                if (this.loading) {
                  return
                }
                this.loading = true
                var condition = { courses: this.refunds, cust: { loginType: this.cust.loginType, custId: this.cust.custId } }
                BaomingApi.refund(condition).then(response => {
                    this.loading = false
                    if (response.code == 100) {
                        this.$router.replace({ name: 'refundsuccess' })
                        // this.getAlreadyBaoduClasses()
                        // this.$dialog({title: '提示', message: '退款结果可能会有延迟，详情请查询您的微信/支付宝通知。'}).then(() => {})
                    } else {
                      this.$dialog.alert({ message: '退款失败，请联络工作人员。'} )
                      this.$router.go(-1)
                    }
                }).catch(()=> {
                  this.loading = false;
                })

            },
            checkClasses(detail) {
                // 选择课程
                if (detail.checked) {
                    this.refunds.push(detail)
                } else {
                    var index = this.refunds.findIndex(item => item.regClassId == detail.regClassId)
                    // console.log(index)
                    this.refunds.splice(index, 1)
                }
            },
            getAlreadyBaoduClasses() {
                BaomingApi.getAlreadyBaoduClasses({custId: this.cust.custId, loginType: this.cust.loginType, reportType: 'ybdbj'}).then(response => {
                    // console.log(response.res)
                    this.regClassMasters = response.res.datas
                    this.payTotal = response.res.payTotal
                    this.reportData = response.res.reportData
                })
            },
            refundClassById(detail) {
              this.$dialog.confirm({title: '是否继续退费？', message: '是否确定申请班级【' + detail.className + '】退款？'}).then(() => {
                this.refunds = []
                this.refunds.push(detail)
                this.refund()
              })

            },
            toTaxURL(eTaxUrl) {
              console.log(eTaxUrl)
              window.location.href = eTaxUrl
            }
        },
        mounted() {
            this.getAlreadyBaoduClasses()
            // console.log(this.$route.query)
            if (this.$route.query.isredirect == undefined || this.$route.query.isredirect == null) {
                this.isredirect = false
            } else {
                if (this.$route.query.isredirect == 'true') {
                    this.isredirect = true
                } else {
                    this.isredirect = false
                }
            }
        }
    }
</script>
<style scoped>
    .class-list {
        width: 95%;
        border-radius: 8px;
        border: 1px solid lightgrey;
        box-shadow: 1px 2px 2px #ddd;
        margin: 10px auto;
        padding: 8px 0px;
        background-color: #cde7f7;
    }

    .title-tips {
        margin-left: 2%;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        color: black;
    }

    .class-split {
        width: 95%;
        height: 1px;
        border-top: 2px dotted lightgrey;
    }
    .pop-btn {
        position: fixed;
        right: 0;
        bottom: 50px;
        background-color: #1989fa;
        padding: 3px 8px;
        font-size: 14px;
        color: white;
        border-left: 2px solid lightblue;
        border-top: 2px solid lightblue;
        border-bottom: 2px solid lightblue;
        z-index: 999;
    }
</style>
